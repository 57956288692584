<template>
  <div
    class="spinner"
    :class="hasOverlay ? 'overlay' : ''"
  >
    <div
      class="spinner-border"
      :class="variant"
      role="status"
      data-testid="spinner"
    >
      <span class="sr-only">Loading</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    variant: {
      type: String,
      default: ''
    },
    hasOverlay: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .spinner {
    &.overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      background: rgba(204, 204, 204, 0.2);

      .spinner-border {
        top: 50%;
        left: 50%;
        position: absolute;
      }
    }
  }
</style>
